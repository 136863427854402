import ApolloClient, { InMemoryCache } from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import store from '../store'
import { logout } from '../store/reducers/slices/user'

export const client = new ApolloClient({
  fetch,
  uri: process.env.GATSBY_API_URL || 'http://localhost:8000/b65e26c795fa42eb12e5aa85771f708a',
  cache: new InMemoryCache(),
  request: (operation) => {
    const token = localStorage.getItem('token')

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
    }
  },
})
