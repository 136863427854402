import { storage } from '../../utils'
import { jwtDecode } from 'jwt-decode'
import { logout } from '../reducers/slices/user'

export const token = store => next => action => {
  const response = next(action)
  const token = store.getState()?.user?.token
  let expiredOrInvalid = true

  if (token !== undefined) {
    try {
      const { exp } = jwtDecode(token)
      expiredOrInvalid = exp < (Date.now() / 1000)
    } catch(e) {
      console.error("Invalid JWT detected")
    }
  }

  if (expiredOrInvalid) {
    storage.removeItem('token')
    if (store.getState()?.user?.isLogged) {
      store.dispatch(logout())
    }
  } else {
    storage.setItem('token', token)
  }

  return response
}
